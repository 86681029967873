import * as React from 'react';

import styled, { css } from 'styled-components';

import { isEven } from '../../../../utils/isEven';

import { theme } from '../../../../styles/2020/theme';

import { Card } from '../../common/Card';
import { Section } from '../../common/Primitives';

type ImageCardsBlockProps = React.ComponentProps<typeof Section> & {
  textAlign?: React.CSSProperties['textAlign'];
  cards: React.ComponentProps<typeof Card>[];
};

export function ImageCardsBlock({ cards, textAlign = 'center', ...props }: ImageCardsBlockProps) {
  const areChildrenEven = isEven(cards.length);

  return (
    <Section {...props}>
      <ImageCardsContainer isEven={areChildrenEven} role="list">
        {cards.map((card) => (
          <Card key={card.title} textAlign={textAlign} {...card} role="listitem" />
        ))}
      </ImageCardsContainer>
    </Section>
  );
}

const ImageCardsContainer = styled.div<{ isEven: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.space.m};
  justify-content: center;

  & > * {
    flex: 0 1 350px;
    max-width: 500px;

    @media (min-width: 500px) {
      flex-basis: 45%;
    }

    ${({ isEven }) =>
      isEven
        ? css`
            @media ${theme.media.min.s} {
              flex-basis: 22%;
            }
          `
        : css`
            @media ${theme.media.min.s} {
              flex-basis: 30%;
            }

            @media ${theme.media.min.m} {
              flex-basis: 17.5%;
            }
          `}
  }
`;
