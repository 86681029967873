import * as React from 'react';

import styled, { css } from 'styled-components';

import type { StyledAsProps } from '../../../../types/props';
import type { StileGtagEventName } from '../../../../types/services';

import { GtagEventParams, tagEvent } from '../../../../utils/tagEvent';

import { theme } from '../../../../styles/2020/theme';

const textPairGap = '1.5em';
const toggleBorderRadius = '40px';

export type ToggleButtonProps = StyledAsProps & {
  gtagEvent?: StileGtagEventName;
  gtagEventParams?: GtagEventParams;
  text?: string;
  tabOne: string;
  tabTwo: string;
  leftTab: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
};

export const ToggleButtonPair = ({
  tabOne,
  tabTwo,
  leftTab,
  gtagEvent,
  gtagEventParams,
  onMouseDown,
  ...props
}: ToggleButtonProps) => {
  // NOTE: this will currently fire an anayltics event (if passed) for the entire component,
  // not for a specific tab!
  const handleMouseDown = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      gtagEvent && tagEvent(gtagEvent, gtagEventParams);
      onMouseDown && onMouseDown(event);
    },
    [gtagEvent, gtagEventParams, onMouseDown]
  );

  const refOne = React.useRef<HTMLParagraphElement | null>(null);
  const refTwo = React.useRef<HTMLParagraphElement | null>(null);

  const [widthOne, setWidthOne] = React.useState(0);
  const [widthTwo, setWidthTwo] = React.useState(0);

  const setListSize = () => {
    if (refOne.current && refTwo.current) {
      setWidthOne(refOne.current.clientWidth);
      setWidthTwo(refTwo.current.clientWidth);
    }
  };

  React.useEffect(() => {
    setListSize();
    window.addEventListener('resize', setListSize);
  }, []);

  return (
    <ToggleButtonContainer {...props} onMouseDown={handleMouseDown}>
      <Container width={leftTab ? widthOne : widthTwo} position={leftTab ? 0 : widthOne} />
      <TextPair>
        <span ref={refOne}>{tabOne}</span>
        <span ref={refTwo}>{tabTwo}</span>
      </TextPair>
    </ToggleButtonContainer>
  );
};

const ToggleButtonContainer = styled.button<ToggleButtonProps>`
  font-size: ${theme.fontSizes.l};
  font-weight: 600;
  border: 1px solid ${theme.colors.grey3};
  background-color: ${theme.colors.grey1};
  border-radius: ${toggleBorderRadius};
  padding: ${theme.space.s} ${theme.space.m};
  position: relative;
`;

const TextPair = styled.div`
  display: flex;
  gap: ${textPairGap};

  & > * {
    z-index: 1;
  }
`;

const Container = styled.div<{ width: number; position: number }>`
  position: absolute;
  top: 12.5%;
  border-radius: ${toggleBorderRadius};
  height: 75%;
  width: calc(${({ width }) => width}px + ${textPairGap});
  background-color: ${theme.colors.brand};

  ${({ position }) => {
    if (position === 0) {
      return css`
        && {
          transform: translateX(calc(${position}px - (${textPairGap} / 2)));
          transition: width 0.1s, transform ${theme.transitions.short};
          transition-timing-function: ease-in-out;
          }
        }
      `;
    } else {
      return css`
        && {
          transform: translateX(calc(${textPairGap} + ${position}px - (${textPairGap} / 2)));
          transition: width 0.1s, transform ${theme.transitions.short};
          transition-timing-function: ease-in-out;
          }
        }
      `;
    }
  }};
`;
