import * as React from 'react';

import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

import { Column } from '../../layout/Column';
import { Row } from '../../layout/Row';

import { H2, Text } from '../Primitives';

type ScrollCardsProps = {
  title: string;
  items: {
    text: string;
    title: string;
    image: React.ReactNode;
  }[];
  itemWidth?: React.CSSProperties['flexBasis'];
};

export function ScrollCards({ title, items, itemWidth = 'auto' }: ScrollCardsProps) {
  const scrollThreshold = 20;

  const containerRef = React.useRef<HTMLElement>(null);

  const [leftArrow, setLeftArrow] = React.useState(false);
  const [rightArrow, setRightArrow] = React.useState(true);

  const onScroll = () => {
    const { current: scrollContainer } = containerRef;
    if (!scrollContainer) return;

    // scroll width is the total width of the containerRef
    // client width is the total width displayed to the user
    // scroll - client gives the left offset when the user is scrolled all the way to right.
    const rightEnd = scrollContainer.scrollWidth - scrollContainer.clientWidth;

    setRightArrow(scrollContainer.scrollLeft < rightEnd - scrollThreshold);
    setLeftArrow(scrollContainer.scrollLeft > scrollThreshold);
  };

  const scrollToStart = () => {
    const { current: scrollContainer } = containerRef;
    if (!scrollContainer) return;

    scrollContainer.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });

    // don't manually set left/right arrow; leave it dynamic.
    onScroll();
  };

  const scrollToEnd = () => {
    const { current: scrollContainer } = containerRef;
    if (!scrollContainer) return;

    const rightEnd = scrollContainer.scrollWidth - scrollContainer.clientWidth;

    scrollContainer.scrollTo({
      left: rightEnd,
      top: 0,
      behavior: 'smooth',
    });

    onScroll();
  };

  return (
    <Column>
      <Row justify="space-between">
        <H2 size="l">{title}</H2>
        <Row>
          <Arrow direction="left" isEnabled={leftArrow} onClick={scrollToStart} />
          <Arrow direction="right" isEnabled={rightArrow} onClick={scrollToEnd} />
        </Row>
      </Row>

      <ScrollContainer
        innerRef={containerRef}
        vertical={false}
        hideScrollbars={false}
        onScroll={onScroll}
      >
        <ItemContainer itemWidth={itemWidth}>
          {items.map((item, index) => (
            <ScrollCard key={`${title}_${item.title}_${index}`} {...item} />
          ))}
        </ItemContainer>
      </ScrollContainer>
    </Column>
  );
}

type ScrollCardProps = {
  title: string;
  text: string;
  image: React.ReactNode;
};

export function ScrollCard({ title, text, image }: ScrollCardProps) {
  return (
    <Column space={theme.space.xxxs}>
      {image}

      <Column space="0">
        <Text as="h3" size="s" bold>
          {title}
        </Text>
        <Text as="p" size="s">
          {text}
        </Text>
      </Column>
    </Column>
  );
}

// TODO: use <Icon /> with arrow svgs - larger hit target than tiny rotated borders
const Arrow = styled.div<{ isEnabled: boolean; direction: string }>`
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  cursor: pointer;

  border: solid;
  border-color: ${({ isEnabled }) => (isEnabled ? theme.colors.grey7 : theme.colors.grey3)};
  border-width: 0 3px 3px 0;

  transform: rotate(
    ${({ direction }) => {
      switch (direction) {
        case 'left':
          return 135;
        case 'right':
          return -45;
        case 'up':
          return -135;
        case 'down':
          return 45;
      }
    }}deg
  );
`;

const ItemContainer = styled.div<Pick<ScrollCardsProps, 'itemWidth'>>`
  display: flex;
  gap: ${theme.space.s};
  cursor: grab;

  > * {
    padding-bottom: ${theme.space.xs};
    flex: 0 0 ${({ itemWidth }) => itemWidth};
  }
`;
