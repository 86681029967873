import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import { Section } from 'stile-shared/src/components/2020/common/Primitives';
import { ScrollCards } from 'stile-shared/src/components/2020/common/ScrollCards';
import { ToggleButtonPair } from 'stile-shared/src/components/2020/common/ToggleButtonPair';
import { Center } from 'stile-shared/src/components/2020/layout/Center';
import { Column } from 'stile-shared/src/components/2020/layout/Column';
import { Row } from 'stile-shared/src/components/2020/layout/Row';

import { theme } from 'styles/theme';

export function CurriculumBlock() {
  const [curriculumTab, setCurriculumTab] = React.useState(true);
  return (
    <Center max="100vw">
      <Section>
        <Column space={theme.space.l}>
          <Row space={theme.space.xxxs}>
            <ToggleButtonPair
              tabOne="Middle School"
              tabTwo="High School"
              onClick={() => setCurriculumTab(!curriculumTab)}
              leftTab={curriculumTab}
            ></ToggleButtonPair>
          </Row>

          {curriculumTab && (
            <Column space={theme.space.xl}>
              <ScrollCards
                title="Grade 6"
                itemWidth="250px"
                items={[
                  {
                    title: 'Introduction to Science',
                    text: `How do scientists work?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G6-Intro-science.jpg"
                        alt="A closeup of a student blowing bubbles towards the camera"
                      />
                    ),
                  },
                  {
                    title: 'Food Chains and Food Webs',
                    text: `Why do cats have slit-shaped pupils?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G6-Food-Chains.jpg"
                        alt="A closeup of a cat with heterochromatic eyes crouched in the grass"
                      />
                    ),
                  },
                  {
                    title: 'The Importance of Biodiversity',
                    text: `Do we need to save the bees?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G6-Bees.jpg"
                        alt="A butterfly and a bee on a flower"
                      />
                    ),
                  },
                  {
                    title: 'Cells',
                    text: `Are you ready to meet lab-grown meat?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G6-Cells.jpg"
                        alt="A closeup of gloved hands holding a petri dish with a small piece of meat in it"
                      />
                    ),
                  },
                  {
                    title: 'Our Place in Space',
                    text: `Can we travel to the Sun?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G6-Our-Place-In-Space.jpg"
                        alt="A computer image of a satellite approaching the Sun"
                      />
                    ),
                  },
                  {
                    title: 'Heat',
                    text: `How can I cook the perfect pizza?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G6-Heat.jpg"
                        alt="The inside of a pizza oven, with fire and two pizzas cooking inside"
                      />
                    ),
                  },
                  {
                    title: 'Heat',
                    text: `Engineering challenge: Build a solar oven`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G6-Engineering-Challenge.jpg"
                        alt="A concave reflective disk with a holder in the middle is placed outside on a rocky surface"
                      />
                    ),
                  },
                  {
                    title: 'Light',
                    text: `How can my smartphone be used as a microscope?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G6-Light.jpg"
                        alt="Long-exposure light trails in a curving pattern through a dim space"
                      />
                    ),
                  },
                  {
                    title: 'Elements and Compounds',
                    text: `What happens if the world runs out of helium?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G6-Elements-Compounds.jpg"
                        alt="Two connected baskets sit on a rocky outcrop, with a lake giving of clouds of gas in the background"
                      />
                    ),
                  },
                  {
                    title: 'Student Research Project',
                    text: `Lessons designed to teach students how to complete scientific research`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G6-Student-Research-Project.jpg"
                        alt="Two students lean over a tub where plans are growing"
                      />
                    ),
                  },
                ]}
              />

              <ScrollCards
                title="Grade 7"
                itemWidth="250px"
                items={[
                  {
                    title: 'Genetics',
                    text: `How can genes increase the risk of cancer?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G7-genetics.jpg"
                        alt="A family is shown sitting together on a couch and smiling"
                      />
                    ),
                  },
                  {
                    title: 'Plants',
                    text: `Why are some plants carnivorous?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G7-plants.jpg"
                        alt="A closeup of a venus fly trap"
                      />
                    ),
                  },
                  {
                    title: 'Ecosystems',
                    text: `How can we prevent plastic from harming marine life?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G7-ecosystems.jpg"
                        alt="A sea turtle, surrounded by plastic and other detrius, seen from below"
                      />
                    ),
                  },
                  {
                    title: 'Ecosystems',
                    text: `Project: Communicate the issue`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G7-Project Communicate Issue.jpg"
                        alt="Several people gardening together outside"
                      />
                    ),
                  },
                  {
                    title: 'The Nervous System',
                    text: `Could machines sniff out cancers better than dogs?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G7-nervous-system.jpg"
                        alt="A small dog follows its nose across a lawn"
                      />
                    ),
                  },
                  {
                    title: 'Active Earth',
                    text: `How do we build future-ready cities?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G7-active-earth.jpg"
                        alt="A volcano exploding at night"
                      />
                    ),
                  },
                  {
                    title: 'Newton’s Laws of Motion',
                    text: `How can we use Newton’s Laws in car crash investigations?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G7-newtons-laws-of-motion.jpg"
                        alt="A planet silhouetted against a star, with a satellite to the right"
                      />
                    ),
                  },
                  {
                    title: 'States of Matter',
                    text: `Why do we need liquid water to live on Mars?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G7-states-of-matter.jpg"
                        alt="An astronaut on the surface of Mars"
                      />
                    ),
                  },
                ]}
              />

              <ScrollCards
                title="Grade 8"
                itemWidth="250px"
                items={[
                  {
                    title: 'Earth Systems',
                    text: `How does our planet recycle?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G8-earth-systems.jpg"
                        alt="A wide-angle show of a person standing in a frozen plain, with mountains and an aurora in the sky behind them"
                      />
                    ),
                  },
                  {
                    title: 'Climate Change',
                    text: `Climate change... is there even a debate?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G8-climate-change.jpg"
                        alt="A dried out and dead grassland, with large cracks visible in the soil"
                      />
                    ),
                  },
                  {
                    title: 'Evolution',
                    text: `Are we responsible for the rise of superbugs?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G8-evolution.jpg"
                        alt="A simulated image of blue bacterium in an orange environment"
                      />
                    ),
                  },
                  {
                    title: 'Human Impacts on Ecosystems',
                    text: `Can we prevent a mass extinction event?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G8-human-impacts-ecosystems_.jpg"
                        alt="A large, spreading orange and blue coral seen from underwater"
                      />
                    ),
                  },
                  {
                    title: 'Energy',
                    text: `What can we learn from nature’s energy engineers?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G8-energy.jpg"
                        alt="A closeup of a peacock mantis shrimp"
                      />
                    ),
                  },
                  {
                    title: 'Optional Extra',
                    text: `Engineering challenge: Biomimicry design`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G8-engineering-challenge.jpg"
                        alt="Three blue and yellow birds perched on a branch in the forest"
                      />
                    ),
                  },
                  {
                    title: 'Energy Conservation',
                    text: `Can we use ocean waves to produce electricity?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G8-energy-conservation.jpg"
                        alt="A surfer rides a very large wave"
                      />
                    ),
                  },
                  {
                    title: 'Non-contact Forces',
                    text: `Are we on track for sustainable transport?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G8-non-contact-forces.jpg"
                        alt="A model of a futuristic train station, with a sleek, clear-sided train shaped like a bullet train"
                      />
                    ),
                  },
                ]}
              />
            </Column>
          )}

          {!curriculumTab && (
            <Column space={theme.space.xl}>
              <ScrollCards
                title="Earth and Space Sciences"
                itemWidth="250px"
                items={[
                  {
                    title: 'Active Earth',
                    text: `How do we build future-ready cities?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G7-active-earth.jpg"
                        alt="A volcano exploding at night"
                      />
                    ),
                  },
                  {
                    title: 'Climate Change',
                    text: `Climate change... is there even a debate?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G8-climate-change.jpg"
                        alt="A dried out and dead grassland, with large cracks visible in the soil"
                      />
                    ),
                  },
                  {
                    title: 'Earth Systems',
                    text: `How does our planet recycle?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G8-earth-systems.jpg"
                        alt="A wide-angle show of a person standing in a frozen plain, with mountains and an aurora in the sky behind them"
                      />
                    ),
                  },
                  {
                    title: 'The Universe',
                    text: `How to gravitational waves give us a new way of understanding the universe?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/universe.jpeg"
                        alt="A swirling nebula with orange tendrils and pink patches"
                      />
                    ),
                  },
                  {
                    title: 'Water Cycle',
                    text: `Would you ever drink your own urine?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/water-cycle.jpeg"
                        alt="A person in a blue waterproof jacket, smiling and head tilted upwards, standing in the rain"
                      />
                    ),
                  },
                ]}
              />

              <ScrollCards
                title="Life Sciences"
                itemWidth="250px"
                items={[
                  {
                    title: 'Ecosystems',
                    text: `How can we prevent plastic from harming marine life?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G7-ecosystems.jpg"
                        alt="A sea turtle, surrounded by plastic and other detrius, seen from below"
                      />
                    ),
                  },
                  {
                    title: 'Evolution',
                    text: `Are we responsible for the rise of superbugs?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G8-evolution.jpg"
                        alt="A simulated image of blue bacterium in an orange environment"
                      />
                    ),
                  },
                  {
                    title: 'Genetics',
                    text: `How can genes increase the risk of cancer?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G7-genetics.jpg"
                        alt="A family is shown sitting together on a couch and smiling"
                      />
                    ),
                  },
                  {
                    title: 'Immune System',
                    text: `How can we protect communities from diseases?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/immune-system.jpeg"
                        alt="A 3 dimensional computer model of bacterial cells"
                      />
                    ),
                  },
                  {
                    title: 'Human Impacts on Ecosystems',
                    text: `Can we prevent a mass extinction event?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G8-human-impacts-ecosystems_.jpg"
                        alt="A large, spreading orange and blue coral seen from underwater"
                      />
                    ),
                  },
                  {
                    title: 'The Importance of Biodiversity',
                    text: `Do we need to save the bees?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G6-Bees.jpg"
                        alt="A butterfly and a bee on a flower"
                      />
                    ),
                  },
                  {
                    title: 'Food Chains and Food Webs',
                    text: `Why do cats have slit-shaped pupils?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G6-Food-Chains.jpg"
                        alt="A closeup of a cat with heterochromatic eyes crouched in the grass"
                      />
                    ),
                  },
                  {
                    title: 'Body Systems',
                    text: `What does it take to be a cold-blooded killer?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/body-systems.jpeg"
                        alt="A brown snake coiled around a tree branch, against a black background"
                      />
                    ),
                  },
                  {
                    title: 'Endocrine System',
                    text: `Will staring at your phone screen before bed affect your sleep?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/endocrine-system.jpeg"
                        alt="A person lying on their bed, with their phone on and next to the pillow"
                      />
                    ),
                  },
                  {
                    title: 'Nervous System',
                    text: `Could machines sniff out cancers better than dogs?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G7-nervous-system.jpg"
                        alt="A small dog follows its nose across a lawn"
                      />
                    ),
                  },
                  {
                    title: 'Plants',
                    text: `Why are some plants carnivorous?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G7-plants.jpg"
                        alt="A closeup of a venus fly trap"
                      />
                    ),
                  },
                  {
                    title: 'Cells',
                    text: `Are you ready to meet lab-grown meat?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G6-Cells.jpg"
                        alt="A closeup of gloved hands holding a petri dish with a small piece of meat in it"
                      />
                    ),
                  },
                ]}
              />

              <ScrollCards
                title="Physical Sciences"
                itemWidth="250px"
                items={[
                  {
                    title: 'Atoms',
                    text: `How can the building blocks of atoms help us see further?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/atoms.jpeg"
                        alt="A 3 dimensional depiction of an atom, with a blue nucleus and red electrons"
                      />
                    ),
                  },
                  {
                    title: 'Kinematics',
                    text: `Are self-driving cars the way of the future?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/kinematics.jpeg"
                        alt="A bullet train travelling at high speed, with a blurred background"
                      />
                    ),
                  },
                  {
                    title: 'Light',
                    text: `How can my smartphone be used as a microscope?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G6-Light.jpg"
                        alt="Long-exposure light trails in a curving pattern through a dim space"
                      />
                    ),
                  },
                  {
                    title: 'Newton’s Laws of Motion',
                    text: `How can we apply Newton’s Laws to car crash investigations?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/newtons-laws-of-motion.jpeg"
                        alt="A planet silhouetted against a star, with a satellite to the right"
                      />
                    ),
                  },
                  {
                    title: 'Reaction Types',
                    text: `Are self-healing space suits science fiction or just science?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/reaction-types.jpeg"
                        alt="A city at night seen from a high location, with fireworks in the sky above the city"
                      />
                    ),
                  },
                  {
                    title: 'Reaction and Energy',
                    text: `Are bionic leaves better than the real thing?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/reactions-and-energy.jpeg"
                        alt="An electric charge being used to light a gas heating element"
                      />
                    ),
                  },
                  {
                    title: 'Periodic Table',
                    text: `How do exploding stars create heavy metals?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/periodic-table.jpeg"
                        alt="A simulation of a star with matter swirling around it"
                      />
                    ),
                  },
                  {
                    title: 'Chemical Reactions',
                    text: `What happens when sodium explodes in water?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/chemical-reactions.jpeg"
                        alt="A close up of a small piece of metal burning with a very bright yellow flame and producing a large amount of smoke"
                      />
                    ),
                  },
                  {
                    title: 'Acids and Bases',
                    text: `Why are our oceans becoming more acidic?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/acids-and-bases.jpeg"
                        alt="A close up of a nautilus swimming through a reef"
                      />
                    ),
                  },
                  {
                    title: 'Heat',
                    text: `How can I cook the perfect pizza?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G6-Heat.jpg"
                        alt="The inside of a pizza oven, with fire and two pizzas cooking inside"
                      />
                    ),
                  },
                  {
                    title: 'Forces',
                    text: `How can you scale a wall like a gecko?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/forces.jpeg"
                        alt="A gecko standing on some large leaves"
                      />
                    ),
                  },
                  {
                    title: 'Energy',
                    text: `What can we learn from nature’s energy engineers?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G8-energy.jpg"
                        alt="A closeup of a peacock mantis shrimp"
                      />
                    ),
                  },
                  {
                    title: 'Energy Conservation',
                    text: `Can we use ocean waves to produce electricity?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G8-energy-conservation.jpg"
                        alt="A surfer rides a very large wave"
                      />
                    ),
                  },
                  {
                    title: 'States of Matter',
                    text: `Why do we need liquid water to live on Mars?`,
                    image: (
                      <StaticImage
                        aspectRatio={1.75}
                        src="../../../../stile-shared/assets/images/home/us_curriculum/G7-states-of-matter.jpg"
                        alt="An astronaut on the surface of Mars"
                      />
                    ),
                  },
                ]}
              />
            </Column>
          )}
        </Column>
      </Section>
    </Center>
  );
}
